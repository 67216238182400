<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="clearAllIntervals"
            size="36"
            style="cursor: pointer;"
            color="#e5e5e5"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#333" size="28" style="padding-bottom: 8px;"
            >mdi-form-textbox-password</v-icon
          >
          <span style="font-size: 19pt; text-wrap: nowrap;" class="fonte-bold">
            Senha
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Gerador de senhas
              </span>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column py-6">
                <span class="fonte-bold mb-6" style="font-size: 24pt;">
                  {{ otp_code || "* * * *" }}
                </span>
                <v-progress-linear
                  :indeterminate="!otp_code"
                  :value="otp_code ? progressPercentage : 0"
                  height="30"
                  striped
                  color="black"
                  style="width: 100%; border-radius: 12px;"
                  rounded
                >
                  <template v-slot:default="{ value }">
                    <strong class="fonte-bold white--text">
                      {{ !otp_code && !seconds ? "Gerando..." : `Expira em ${seconds} segundos` }}
                    </strong>
                  </template>
                </v-progress-linear>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      otp_code: "",
      step: "1",
      loading: false,
      seconds: 60,
      progressPercentage: 0,
      expires: null,
      timeout: null
    };
  },
  methods: {
    ...mapActions(["generateCode"]),
    clearAllIntervals() {
      clearInterval(this.timeout);
      this.$router.push("/dashboard");
    },
    initCodeGen() {
      this.generateCode()
        .then(res => {
          this.otp_code = res.code;
          this.expires = res.expires;
        })
        .catch(res => {
          console.log(res);
        });
    }
  },
  beforeDestroy() {
    this.clearAllIntervals();
  },
  mounted() {
    this.initCodeGen();
    this.timeout = setInterval(() => {
      if (this.expires) {
        this.seconds = this.$moment(this.expires).diff(
          this.$moment(),
          "seconds"
        );
        this.progressPercentage = ((60 - this.seconds) / 60) * 100;
        if (this.seconds <= 0) {
          this.otp_code = "";
          this.seconds = 60;
          this.expires = null;
          this.initCodeGen();
        }
      }
    }, 1000);
  }
};
</script>
